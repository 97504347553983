<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-select
          v-model="formData.action"
          filterable
          @change="changeAction"
          style="width: 100%"
        >
          <el-option
            v-for="callToAction in callToActionList"
            :key="callToAction"
            :label="callToAction"
            :value="callToAction"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select
          class="ml-2"
          style="width: 100%"
          filterable
          v-show="showLang"
          v-model="formData.lang"
          @change="changeCallToAction"
        >
          <el-option
            v-for="lang in languages"
            :key="lang.key"
            :label="lang.key"
            :value="lang.value"
          ></el-option> </el-select
      ></el-col>
    </el-row>
  </div>
</template>
<script>
import { languages, callToActions } from '../index';
import { callToActionList } from '@/views/google/create/index';
export default {
  props: {
    //类型。video | img
    type: {
      type: String,
      default: 'video',
    },
    value: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      languages,
      formData: {},
    };
  },
  computed: {
    // 行动号召
    callToActionList() {
      return ['自动'].concat(callToActionList.map((v) => v.key));
    },
    // 是否展示语种选择
    showLang() {
      let flag = true;
      if (this.formData.action === '自动' || this.type === 'video') {
        this.formData.lang = null;
        flag = false;
      }
      return flag;
    },
    // 行动号召的key
    key() {
      return callToActionList.find((v) => v.key === this.formData.action).value;
    },
    // 对应语种的行动号召
    keyword() {
      return callToActions[this.formData.lang][this.formData.action];
    },
    //导出数据
    emitData() {
      if (this.type === 'img') {
        return {
          ...this.formData,
          key: this.key,
          keyword: this.formData.lang ? this.keyword : null,
        };
      } else {
        return [
          {
            ...this.formData,
            key: this.key,
            keyword: this.formData.lang ? this.keyword : null,
          },
        ];
      }
    },
  },
  watch: {
    value: {
      handler() {
        if (this.type === 'video') {
          this.formData =
            this.value?.length && Object.keys(this.value[0])?.length
              ? this.value[0]
              : {
                  action: '自动',
                  lang: '',
                };
        } else {
          this.formData =
            this.value && Object.keys(this.value).length
              ? this.value
              : {
                  action: '自动',
                  lang: '',
                };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeAction(v) {
      if (v == '自动') {
        this.formData.key = null;
        this.formData.lang = null;
        this.formData.keyword = null;
      }
      this.changeCallToAction();
    },
    /**
     * 触发change
     */
    changeCallToAction() {
      this.$emit('input', this.emitData);
    },
  },
};
</script>
<style lang="scss" scoped></style>
