<template>
  <div>
    <div class="flex mr-2">
      <div
        v-for="(img, index) in value"
        class="flex mr-4"
      >
        <div class="relative w-24 h-24">
          <el-image
            class="w-24 h-24"
            :src="img.url"
            fit="contain"
          ></el-image>
          <i
            class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
            @click="deleItem(index)"
            v-if="!disabled"
          ></i>
        </div>
      </div>

      <el-upload
        v-if="value.length < limit && !disabled"
        action="#"
        accept=".jpg,.png"
        :show-file-list="false"
        :http-request="(file) => uploadImgHandle(file)"
        :before-upload="(file) => selectImg(file)"
      >
        <el-image
          class="w-24 h-24"
          :src="addImgIcon"
        ></el-image>
        <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
      </el-upload>
    </div>
    <ImgCut
      :dialog-visible.sync="dialogVisible"
      :image-src="imgUrl"
      :cut-img-params="cutImgParams"
      :file-msg="fileMsg"
      @uploadImg="uploadCutImg"
    />
  </div>
</template>
<script>
import ImgCut from '@/views/snapchat/components/imgCut/index';
import getImageSize from '@adCommonComponent/utils/getImageSize.js';
import { uploadImg } from '@/api/google/create.js';

export default {
  components: { ImgCut },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'DEMAND_GEN_VIDEO_RESPONSIVE_AD',
    },
    currentAccount: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addImgIcon: require('@/assets/icon/addImg.png'),
      dialogVisible: false, //图片裁剪弹窗
      imgUrl: '', //当前裁剪图片的url地址
      cutImgParams: {}, //裁剪图片参数
      fileMsg: {}, //裁剪图片的信息
      initCutParams: {
        outputType: 'png', //输出图片的格式
        infoTrue: true,
      },
    };
  },

  computed: {
    limit() {
      return this.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD' ? 1 : 5;
    },
  },
  methods: {
    /**
     * 删除徽标
     */
    deleItem(index) {
      this.value.splice(index, 1);
      this.$emit('input', this.value);
    },
    /**
     * 校验尺寸
     */
    validateImgSize(width, height) {
      let isMin = true, //是否大于最小尺寸,支持裁剪
        isPx = false; // 是否符合尺寸，直接上传
      let message = ''; //提示信息
      isPx = height >= 128 && width >= 128 && width / height == 1;
      if (!isPx) {
        if (width < 128 || height < 128) {
          isMin = false;
          message = '图片最小尺寸为 128*128';
        }
      }
      return { isPx, isMin, message };
    },
    /**
     * 设置裁剪参数
     */
    setCutParams(data) {
      this.cutImgParams = { ...this.initCutParams, ...data };
    },
    /**
     * 选择图片
     */
    async selectImg(file) {
      console.log(file);
      const imgTypes = ['image/png', 'image/jpeg'];
      this.fileMsg = {
        name: file.name,
      };
      let isPx = false;
      let isMin = false;
      let isType = imgTypes.includes(file.type);
      const fileSize = file.size / 1024 / 1024;
      let isSize = fileSize <= 5;
      // 格式限制
      if (isType) {
        if (!file.url) {
          file.url = URL.createObjectURL(file);
        }
        if (!isSize) {
          this.$message.error('图片大小不得超过5M');
        }
        await getImageSize(file.url)
          .then((res) => {
            const { width, height } = res;
            console.log(width, height);
            let obj = this.validateImgSize(width, height);
            console.log(obj);
            isPx = obj.isPx;
            isMin = obj.isMin;
            if (!isPx) {
              if (!isMin) {
                this.$message.error(obj.message);
              } else {
                this.setCutParams({
                  autoCropWidth: 1200,
                  autoCropHeight: 1200,
                  fixedBox: false,
                  limitMinSize: [128, 128],
                  fixedNumber: [1, 1],
                  infoTrue: true,
                  full: true,
                  centerBox: true,
                  original: true,
                });
                this.dialogVisible = true;
                this.imgUrl = file.url;
              }
            }
          })
          .catch((error) => {
            this.$message.error('文件读取失败');
          });
      } else {
        this.$message.error('文件格式不正确');
      }
      return isPx && isType && isSize && isMin ? Promise.resolve() : Promise.reject();
    },
    /**
     * 上传图片
     */
    uploadImgHandle(file) {
      console.log(file);
      this.$showLoading();
      uploadImg({ raw: file.file }, this.currentAccount.id)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            if (this.value.length >= 5) {
              return;
            } else {
              this.value.push(res.data);
              this.$emit('input', this.value);
            }
          }
        })
        .catch((err) => {});
    },
    /**
     * 接收裁剪过的图片上传
     */
    uploadCutImg(file) {
      console.log(file);
      this.uploadImgHandle({ file: file });
    },
  },
};
</script>
<style lang="scss" scoped></style>
