<template>
  <div>
    <div
      v-for="(item, index) in titleList"
      :key="index"
      class="mb-2"
    >
      <!-- <el-row>
        <el-col :span="20"> -->
      <el-input
        class="mr-1 hideCount"
        v-model="item.name"
        :placeholder="`最多添加5个${type}`"
        :maxlength="maxlength"
        @input="change"
      >
        <template #suffix>
          <span
            class="text-[#666666] pr-1"
            style="font-size: 11px"
          >
            {{ getByteLength(item.name) }}/{{ maxlength }}
          </span>
        </template>
      </el-input>
      <!-- </el-col>
        <el-col
          class="ml-1"
          :span="3"
        > -->
      <el-button
        type="text"
        v-if="index === 0 && titleList.length < 5"
        @click="addItem"
        >添加</el-button
      >
      <el-button
        type="text"
        @click="deleItem(index)"
        >删除</el-button
      >
      <!-- </el-col>
      </el-row> -->
    </div>
  </div>
</template>
<script>
import { getByteLength } from '@/views/google/create/components/creatives/index';
export default {
  props: {
    type: {
      type: String,
      default: '标题',
    },
    value: {
      type: Array,
      default: () => [],
    },
    maxlength: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {};
  },
  computed: {
    titleList: {
      get() {
        return this.value.length
          ? this.value.map((v) => {
              return {
                name: v,
              };
            })
          : [{ name: '' }];
      },
      set() {},
    },
  },
  methods: {
    getByteLength(str) {
      return getByteLength(str);
    },
    /**
     * 添加
     */
    addItem() {
      this.titleList.push({ name: '' });
      this.change();
    },
    /**
     *删除
     */
    deleItem(index) {
      if (this.titleList.length === 1) {
        this.$message({
          type: 'warning',
          message: `请至少保留一个${this.type}`,
        });
        return false;
      }
      this.titleList.splice(index, 1);
      this.change();
    },
    /**
     * 导出数据
     */
    change() {
      this.$emit(
        'input',
        this.titleList.map((v) => {
          if (v.name) {
            return v.name;
          }
        }),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.hide-count .el-input__count {
  display: none; /* 隐藏默认计数 */
}
</style>
