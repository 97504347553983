<template>
  <div class="flex flex-wrap uploadGoogleImg">
    <el-upload
      list-type="picture-card"
      ref="upload"
      class="mr-2 mb-4"
      v-bind="defaultParams"
      :file-list="videoList"
      :on-change="onChange"
      :auto-upload="false"
      v-show="videoList.length < defaultParams.limit"
    >
      <div class="leading-4 text-xs border w-full h-full flex flex-col justify-center items-center rounded-md p-4">
        <div class="mb-2">
          <el-button
            type="primary"
            size="medium"
            @click.stop="openYouTube"
            >youtube选择</el-button
          >
        </div>
        <el-button
          type="primary"
          size="medium"
          @click.stop="uploadYouTube"
          >点击上传</el-button
        >
        <div class="text-gray-700 leading-6 mt-1">
          <div>横向视频：16:9</div>
          <div>方形视频：1:1</div>
          <div>纵向视频：9:16</div>
          <div>格式为MP4</div>
        </div>
      </div>
    </el-upload>
    <div
      v-for="(file, index) in videoList"
      :key="file.uid"
      class="mr-4 mb-4 w-[160px] h-[240px] border-[1px] box-border border-dashed divide-inherit border-[#f2f2f2]"
    >
      <div class="relative w-full h-full leading-none">
        <youtube
          class="w-full h-full"
          :video-id="file.videoId"
          v-bind="params"
          v-if="file.type === 'youtube' || file.videoId"
        ></youtube>
        <video
          class="w-full h-full block"
          :src="file.ossFileUrl"
          :poster="file.thUrl"
          controls
          v-else
        ></video>
        <div
          class="w-full"
          v-if="file.status === 'processing'"
        >
          <el-progress
            :stroke-width="10"
            :percentage="file.process"
          ></el-progress>
        </div>
        <!-- 删除 -->
        <div
          class="absolute -right-2 -top-2 w-6 h-6 leading-none cursor-pointer z-20"
          v-if="!disabled"
        >
          <i
            class="el-icon-circle-close hover:text-primary text-[24px]"
            @click.stop="deleteItem(index)"
          ></i>
        </div>
        <!-- 重新上传 -->
        <div
          class="z-10 absolute left-0 top-0 w-full h-full bg-white bg-opacity-75 bottom-0 right-0 m-auto flex flex-col justify-center items-center"
          v-if="file.process === -1 && file.status === 'failed'"
        >
          <div class="text-red-600 mt-3 text-base">
            <i class="el-icon-warning !text-red-600 !text-base"></i> 上传失败
          </div>
          <el-button
            size="mini"
            type="primary"
            @click.stop="uploadFile(file)"
            >重新上传</el-button
          >
        </div>
        <div
          class="z-10 absolute left-0 top-0 w-full h-full bg-white bg-opacity-75 bottom-0 right-0 m-auto flex flex-col justify-center items-center"
          v-if="file.status === 'failed' && file.process == 100"
        >
          <div class="text-red-600 mt-3 text-base">
            <i class="el-icon-warning !text-red-600 !text-base"></i> 视频审核失败
          </div>
        </div>
      </div>
    </div>
    <!-- 本地上传视频到youtube -->
    <UploadVideoYoutube
      :upload-video-youtube-show.sync="uploadVideoYoutubeShow"
      @updateValue="(list) => setValue(list, 'upload')"
    />
    <!-- 从youtube 选择视频 -->
    <YoutubeVideos
      :select-youtube-video-show.sync="selectYoutubeVideoShow"
      @updateValue="(list) => setValue(list, 'youtube')"
    />
  </div>
</template>

<script>
import UploadVideoYoutube from '@/views/google/components/UploadVideoYoutube/index.vue';
import YoutubeVideos from '@/views/google/components/YoutubeVideos/index.vue';
import { objectDeepEqual } from '@/utils/utils';
import { getVideoProcess, syncUploadVideo } from '@/api/google/youtube';
const statusList = [
  { key: 'failed', label: '视频处理失败' },
  { key: 'processing', label: '视频当前正在处理中' },
  { key: 'succeeded', label: '视频已成功处理' },
  { key: 'terminated', label: '处理信息不再可用' },
  { key: 'unknown', label: '未知' },
];
export default {
  components: { UploadVideoYoutube, YoutubeVideos },
  props: {
    value: { type: Array, default: () => [] },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      //上传upload 参数
      defaultParams: {
        limit: 5,
        action: '#',
        accept: '.mp4',
        multiple: true,
        'show-file-list': false,
        'list-type': 'picture-card',
      },
      uploadVideoYoutubeShow: false, //上传视频到youtube弹窗
      selectYoutubeVideoShow: false, //从youtube选择视频
      processMap: {}, //查询进度的map 数据
      params: {
        'player-width': 160,
        'player-height': 240,
      },
      timer: null,
    };
  },
  computed: {
    videoList: {
      //当前视频列表
      get() {
        return this.value;
      },
      set() {},
    },
  },
  watch: {
    value() {
      console.log('VALUEcHANGE');
      console.log(this.value);
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    /**
     * 更新视频数组
     */
    updateValue(list) {
      this.$emit('input', [...list]);
    },
    /**
     * 获取本地上传的视频数组
     */
    setValue(list, type) {
      list.forEach((video) => {
        video.type = type;
        if (type === 'upload') {
          video.process = 0;
          video.status = 'processing';
          this.onChange(video);
        } else {
          video.process = 100;
          video.status = 'succeeded';
        }
      });
      let listAll = this.videoList.concat(list);
      console.log(list);
      this.updateValue(listAll);
    },
    /**
     * 上传视频到youtube
     */
    uploadYouTube() {
      this.uploadVideoYoutubeShow = true;
    },
    /**
     * 打开YouTube
     */
    openYouTube() {
      //   this.$emit('openYouTube');
      this.selectYoutubeVideoShow = true;
    },
    /**
     * 删除视频
     */
    deleteItem(index) {
      this.videoList.splice(index, 1);
      //   console.log(this.videoList, 'this.videoList');
      this.updateValue(this.videoList);
    },

    /**
     * 触发change 事件
     */
    onChange(file) {
      console.log(file, 'change');
      if (this.videoList.length >= this.defaultParams.limit) return;
      this.uploadFile(file);
      //   this.getProgress();
    },
    /**
     * 上传进度条
     */
    getProgress() {
      clearTimeout(this.timer);
      const ids = this.videoList
        .filter((v) => v.processId && v.process !== -1 && v.status === 'processing')
        .map((item) => item.processId);
      console.log(ids);
      if (!ids.length) {
        return;
      }
      getVideoProcess({ recordIds: ids.join(','), searchStatus: true })
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              item.status = item.processingStatus;
              this.processMap[item.recordId] = { ...item };
              let obj = { ...item, status: item.processingStatus || 'processing' };
              const index = this.videoList.findIndex((v) => v.processId === item.recordId);
              this.videoList[index] = { ...this.videoList[index], ...obj, ...obj.video };
            });
            this.updateValue(this.videoList);
            this.timer = setTimeout(() => {
              this.getProgress();
            }, 5000);
          }
        })
        .catch(() => {
          ids.forEach((item) => {
            const index = this.videoList.findIndex((v = v.recordId == item));
            this.videoList[index] = { ...this.videoList[index], process: 0, status: 'failed' };
            this.updateValue(this.videoList);
          });
        });
    },
    /**
     * 上传图片到YouTube
     */
    uploadFile(data) {
      console.log(data);
      syncUploadVideo(data)
        .then((res) => {
          const list = this.videoList;
          const index = list.findIndex((item) => item.id === data.id);
          if (res.code === 0) {
            list[index] = { ...list[index], processId: res.data, status: 'processing', process: 0 };
            this.getProgress();
          } else {
            list[index] = { ...list[index], status: 'failed', process: 0 };
          }
          this.updateValue(list);
        })
        .catch(() => {
          const list = this.videoList;
          const index = list.findIndex((item) => item.id === data.id);
          list[index] = { ...list[index], status: 'failed', process: 0 };
          this.updateValue(list);
        });
    },
  },
};
</script>
<style lang="scss">
.uploadGoogleImg {
  .el-upload--picture-card {
    width: 160px;
    height: 240px;
  }
}
</style>
