<template>
  <div class="flex flex-wrap uploadGoogleImg">
    <el-upload
      v-bind="defaultParams"
      :file-list="imageList"
      :on-change="onChange"
      :auto-upload="false"
      list-type="picture-card"
      ref="upload"
      class="mr-2 mb-4"
      v-show="imageList.length < defaultParams.limit"
    >
      <div class="leading-4 text-xs border w-full h-full flex flex-col justify-center items-center rounded-md p-4">
        <!-- <div class="mb-2">
          <el-button
            type="primary"
            size="medium"
            @click.stop="openLibrary"
            >素材库选择</el-button
          >
        </div> -->
        <el-button
          type="primary"
          size="medium"
          >点击上传</el-button
        >
        <div class="text-gray-700 leading-6 mt-1">
          <div>横向图片：1.91:1</div>
          <div>方形图片：1:1</div>
          <div>纵向图片：4:5</div>
          <div>格式为PNG或JPEG</div>
        </div>
      </div>
    </el-upload>

    <div
      v-for="(file, index) in imageList"
      :key="file.uid"
      class="mr-4 mb-4 w-[160px] h-[240px] border-[1px] box-border border-dashed divide-inherit border-[#f2f2f2]"
    >
      <div class="relative w-full h-full leading-none box-border">
        <el-image
          :src="file.url"
          class="w-full h-full block"
          fit="contain"
        />
        <div
          class="w-full"
          v-if="file.status == 'uploading'"
        >
          <el-progress
            :stroke-width="10"
            :percentage="file.process"
          ></el-progress>
        </div>
        <!-- 删除 -->
        <div
          class="absolute -right-2 -top-2 w-6 h-6 leading-none cursor-pointer z-20"
          v-if="!disabled"
        >
          <i
            class="el-icon-circle-close hover:text-primary text-[24px]"
            @click.stop="deleteItem(index)"
          ></i>
        </div>
        <!-- 重新上传 -->
        <div
          class="z-10 absolute left-0 top-0 w-full h-full bg-white bg-opacity-75 bottom-0 right-0 m-auto flex flex-col justify-center items-center"
          v-if="file.status == 'fail'"
        >
          <div class="text-red-600 mt-3 text-base">
            <i class="el-icon-warning !text-red-600 !text-base"></i> 上传失败
          </div>
          <el-button
            size="mini"
            type="primary"
            @click.stop="uploadFile(file)"
            >重新上传</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImageSize from '@adCommonComponent/utils/getImageSize.js';
import { uploadImg } from '@/api/google/create.js';
export default {
  props: {
    value: { type: Array, default: () => [] },
    currentAccount: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //上传upload 参数
      defaultParams: {
        limit: 20,
        action: '#',
        accept: '.jpg,.png',
        multiple: true,
        'show-file-list': false,
        'list-type': 'picture-card',
      },
    };
  },
  computed: {
    imageList() {
      return this.value.map((v) => {
        v.id = v.id || v.uid;
        return v;
      });
    },
  },
  methods: {
    /**
     * 更新图片数组
     */
    updateValue(list) {
      console.log(list, 'eeeeee');
      this.$emit('update:value', [...list]);
      this.$emit('change', list);
    },
    /**
     * 打开素材库
     */
    openLibrary() {
      this.$emit('openLibrary');
    },
    /**
     * 删除图片
     */
    deleteItem(index) {
      this.imageList.splice(index, 1);
      this.updateValue(this.imageList);
    },
    /**
     * 图片比例误差范围
     * ratio :宽高比
     * errorRatio:误差范围
     */
    getErrorRatio(ratio, errorRatio) {
      return {
        min: ratio - ratio * errorRatio,
        max: ratio + ratio * errorRatio,
      };
    },
    /**
     * 校验图片大小
     * 横向图片 (1.91:1) 推荐尺寸：1200 x 628 最小尺寸要求：600 x 314
     * 方形图片 (1:1)推荐尺寸：1200 x 1200 最小尺寸要求：300 x 300
     * 纵向图片 (4:5)推荐尺寸：960 x 1200 最小尺寸要求：480 x 600
     */
    validateSize(width, height) {
      //图片宽高比
      const ratio = width / height;
      //   横向图片1.91/1 误差1%
      const transverseRatio = this.getErrorRatio(1.91, 0.01);
      const squareRatio = this.getErrorRatio(1, 0.01);
      const portraitRatio = this.getErrorRatio(4 / 5, 0.01);
      //  false 代表三个比例都不符合
      const isTransverse =
        ratio >= transverseRatio.min && ratio <= transverseRatio.max && width >= 600 && height >= 314;
      const isSquare = ratio >= squareRatio.min && ratio <= squareRatio.max && width >= 300 && height >= 300;
      const isPortrait = ratio >= portraitRatio.min && ratio <= portraitRatio.max && width >= 480 && height >= 600;
      return isTransverse || isSquare || isPortrait;
    },
    /**
     * 校验图片格式，大小，比例
     */
    validateImage(file) {
      console.log(file);
      return new Promise((resolve, reject) => {
        const raw = file.raw;
        const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const fileSize = raw.size / 1024 / 1024;
        // 格式限制
        if (imgTypes.includes(raw.type)) {
          // 大小限制
          const isLt5MB = fileSize <= 5;
          if (!isLt5MB) {
            return reject('文件限制在5MB以内');
          }
          getImageSize(file.url)
            .then((res) => {
              const { width, height } = res;
              if (!this.validateSize(width, height)) {
                return reject('请选择1.91:1或者1:1 或者4:5比例的图片,并且尺寸大于最小尺寸');
              }
              resolve();
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else {
          reject('文件格式不正确');
        }
      });
    },
    /**
     * 触发change 事件
     */
    onChange(file) {
      if (this.imageList.length >= this.defaultParams.limit) return;
      const uid = file.uid;
      const index = this.imageList.findIndex((item) => item.uid == uid);
      let list = this.imageList;
      console.log(index, 'index,index,ibndex');
      if (index === -1) {
        console.log(file);
        file.mediaType = 'IMAGE';
        file.name = file.raw.name;
        list.push(file);
        this.updateValue(list);
      }
      this.validateImage(file)
        .then(() => {
          if (index >= 0) {
            list[index] = file;
            this.updateValue(list);
          } else {
            this.$nextTick(() => {
              this.uploadFile(file);
            });
          }
        })
        .catch((err) => {
          list = [...this.imageList];
          const i = list.findIndex((item) => item.uid == uid);
          if (i != -1) {
            list.splice(i, 1);
            this.updateValue(list);
          }
          this.$message.error(err);
        });
    },
    /**
     * 上传进度条
     */
    onProgress(event, file) {
      const uid = file.uid;
      const index = this.imageList.findIndex((item) => item.uid == uid);
      const list = this.imageList;
      let process = event.progress;
      if (index >= 0) {
        list[index].process = process;
        list[index].status = 'uploading';
      }
      this.updateValue(list);
    },
    /**
     * 上传图片到服务器
     */
    uploadFile(data) {
      const uid = data.uid;
      uploadImg(data, this.currentAccount.id, { onUploadProgress: this.onProgress })
        .then((res) => {
          const list = this.imageList;
          const index = list.findIndex((item) => item.uid == uid);
          if (index > -1) {
            if (res.code == 0) {
              list[index] = { ...list[index], ...res.data, url: res.data.url, status: 'success' };
            } else {
              list[index] = { ...list[index], status: 'fail', process: null };
            }
            this.updateValue(list);
          }
        })
        .catch(() => {
          const list = this.imageList;
          const index = list.findIndex((item) => item.uid == uid);
          list[index] = { ...list[index], status: 'fail', process: null };
          this.updateValue(list);
        });
    },
  },
};
</script>
<style lang="scss">
.uploadGoogleImg {
  .el-upload--picture-card {
    width: 160px;
    height: 240px;
  }
}
</style>
